import { LandingHero } from "./LandingHero";
import { Tiles } from "./Tiles";

export const Landing = () => {
  return (
    <div className="">
      <LandingHero />
      <Tiles />
    </div>
  );
};
